<template>
  <div class="index">
    <div class="header">
      <div class="w1300">
        <h5><img src="../assets/images/logo.png"></h5>
      </div>
    </div>
    <div class="banner">
      <div class="w1300">
        <el-carousel 
          trigger="click"
          arrow="hover"
          :height="bannerHeight"
          :interval="6000">
          <el-carousel-item 
            v-for="item in bannerList" 
            :key="item.id">
            <img :src="imgUrl+item.pic">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="nav">
      <div class="w1300">
        <div class="box">
          <span
            :class="{active : categoryId === item.id}" 
            v-for="item in blogNav" 
            :key="item.id"
            @click="changeNav(item)">
            {{item.englishName}}
          </span>
        </div>
      </div>
    </div>
    <div class="w1300">
      <div class="blog">
        <div class="box">
          <div class="list" 
            v-for="item in blogList" 
            :key="item.id">
            <a :href="item.urlPC" target="_blank">
            <div class="img">
              <el-image 
              :src="imgUrl+item.articlePic"
              fit="contain">
              </el-image>
            </div>
            <div class="txt">
              <h5>{{item.title}}</h5>
              <span>{{item.typeName}}</span>
              <p>{{item.subtitle}}</p>
              <div class="tag" v-if="!!item.blogTagList">
                <span 
                  v-for="(items, index) in item.blogTagList" 
                  :key="index">{{items}}</span>
              </div>
              <div class="user">
                <span>{{item.updater}}</span>
              </div>
              <div class="time">
                <div class="left">
                  <span>
                    <img src="../assets/images/icon_1.png">
                    {{item.likes}}
                  </span>
                  <span>
                    <img src="../assets/images/icon_2.png">
                    {{item.visits}}
                  </span>
                </div>
                <div class="right">
                  {{item.updateDatetime}}
                </div>
              </div>
            </div>
            </a>
          </div>
        </div>
        <div class="ad">
          <div class="list" 
            v-for="item in blogAd" 
            :key="item.id">
            <a :href="item.url" target="_blank">
            <img :src="imgUrl+item.pic">
            </a>
          </div>
        </div>
        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="page"
            :page-size.sync="pageNumber"
            :total="totalPage"
            @current-change="getBlogList"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="w1300">
        <div class="box">
          <div class="list">
            <h5>Cardtime</h5>
            <a href="about.html"><p>About us</p></a>
            <a href="contact.html"><p>Contact us</p></a>
            <a href="business.html"><p>Business</p></a>
          </div>
          <div class="list" 
            v-for="item in footNav" 
            :key="item.id">
            <h5>{{item.englishName}}</h5>
            <p 
              v-for="(items, index) in item.blogList" 
              :key="index">
              <a :href="items.urlPC">
                {{items.artTitle}}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {Carousel, CarouselItem, Pagination, Image} from 'element-ui'
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.use(Image)
export default {
  name: '',
  components:{

  },
  data () {
    return {
      //图片地址
      imgUrl: imgUrl,
      //banner
      bannerList:[],
      blogNav: [
        {
          id: 0,
          englishName: 'All'
        }
      ],
      categoryId: 0,
      blogList:[],
      blogAd:[],
      footNav:[],
      //当前页
      page:1,
      //每页个数
      pageNumber:9,
      //总个数
      totalPage: 0,
    }
  },
  computed:{
    bannerHeight() {
      return '300px'
    }
  },
  methods:{
    async getHost(url){
      return '//'+url+'/blog'
    },
    //format时间格式转换
    async formatTime(format){
      let time = new Date(format)
      let Y = time.getFullYear() + '-'
      let M = (time.getMonth() + 1 < 10 ? '0'+(time.getMonth() + 1) : time.getMonth() + 1) + '-'
      let D = (time.getDate() < 10 ? '0'+time.getDate() : time.getDate()) + ' '
      let h = (time.getHours() < 10 ? '0'+time.getHours() : time.getHours()) + ':'
      let m = (time.getMinutes() < 10 ? '0'+time.getMinutes() : time.getMinutes()) + ':'
      let s = time.getSeconds() < 10 ? '0'+time.getSeconds() : time.getSeconds()
      return Y+M+D+h+m+s
    },
    //获取博客banner
    async getBlogBanner(){
      const res = await this.$api.home.getBlogBanner({
        params:{
          subsidiaryCode: 'blog000000001',
          location: 'blog_pc_index',
        }
      })
      this.bannerList = res
    },
    //获取博客分类
    async getBlogNav(){
      const res = await this.$api.home.getBlogNav({
        params:{
          subsidiaryCode: 'blog000000001',
          status: 1,
        }
      })
      for(let i=0; i<res.length; i++){
        this.blogNav.push(res[i])
      }
    },
    async getBlogList(){
      const res = await this.$api.home.getBlogList({
        params:{
          subsidiaryCode: 'blog000000001',
          start: this.page,
          limit: this.pageNumber,
          type: this.categoryId,
        }
      })
      this.totalPage = res.totalCount
      let url = await this.getHost(window.location.host)
      for(let i=0; i<res.list.length; i++){
        res.list[i].urlPC = `${url}/${res.list[i].urlPC}`
        res.list[i].updateDatetime = await this.formatTime(res.list[i].updateDatetime)
      }
      this.blogList = res.list
    },
    async getBlogAd(){
      const res = await this.$api.home.getBlogAd({
        params:{
          subsidiaryCode: 'blog000000001',
          blogType: this.categoryId,
        }
      })
      this.blogAd = res
    },
    //切换博客分类
    changeNav({id}){
      this.page = 1
      this.categoryId = id
      this.getBlogList()
    },
    //获取底部导航
    async getFootNav(){
      const res = await this.$api.home.getFootNav({
        params:{
          subsidiaryCode: 'blog000000001',
        }
      })
      let url = await this.getHost(window.location.host)
      for(let i=0; i<res.length; i++){
        for(let j=0; j<res[i].blogList.length; j++){
          res[i].blogList[j].urlPC = `${url}/${res[i].blogList[j].urlPC}`
        }
      }
      this.footNav = res
    }
  },
  created(){
    //获取博客banner
    this.getBlogBanner()
    //获取博客分类
    this.getBlogNav()
    //获取博客列表
    this.getBlogList()
    //获取播客广告
    this.getBlogAd()
    //获取底部导航
    this.getFootNav()
  },  
  mounted(){

  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
.index{
  background-color: #f2f2f2;
  .w1300{
    width: 1300px;
    margin: 0 auto;
    position: relative;
  }
  .header{
    width: 100%;
    background-color: white;
    h5{
      font-size: 30px;
      font-weight: 600;
      color: #1FC9C2;
      padding: 15px 0;
      img{
        height: 35px;
        display: block;
      }
    }
  }
  .banner{
    padding: 25px 0;
  }
  .nav{
    background-color: white;
    .box{
      display: flex;
      align-items: center;
      justify-content: space-around;
      span{
        font-size: 18px;
        font-weight: 400;
        line-height: 65px;
        padding-bottom: 5px;
        color: #999999;
        position: relative;
        cursor: pointer;
        -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;	
        transition: all cubic-bezier(.18,1,.21,1) .9s;
      }
      span::before{
        content: '';
        width: 100%;
        height: 3px;
        border-radius: 2px;
        background: #fff;
        position: absolute;
        left: 0;
        bottom: 15px;
        -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;	
        transition: all cubic-bezier(.18,1,.21,1) .9s;
      }
      span.active{
        color: #1FC9C2;
      }
      span.active::before{
        background: #1FC9C2;
      }
    }
  }
  .blog{
    padding: 40px 0;
    .box{
      display: flex;
      flex-wrap: wrap;
      .list{
        width: 32%;
        margin-bottom: 2%;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        cursor: pointer;
        .img{
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 15px;
          img{
            -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;	
            transition: all cubic-bezier(.18,1,.21,1) .9s;
          }
        }
        .txt{
          >h5{
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          >span{
            display: inline-block;
            background: rgba(31, 201, 194, .6);
            border-radius: 9px;
            font-size: 12px;
            font-weight: 500;
            color: #FFFFFF;
            padding: 4px 6px;
            margin-top: 6px;
            margin-bottom: 10px;
          }
          >p{
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .tag{
            margin: 10px 0;
            display: flex;
            span {
              border: 1px solid #1FC9C2;
              border-radius: 18px;
              font-size: 12px;
              font-weight: 500;
              color: #1FC9C2;
              margin-right: 10px;
              padding: 5px 10px;
            }
          }
          .user{
            margin-bottom: 10px;
            span{
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
          }
          .time{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left{
              display: flex;
              span{
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                margin-right: 30px;
                img{
                  width: 16px;
                  margin-right: 6px;
                }
              }
            }
            .right{
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }
      .list:nth-child(3n-1){
        margin-left: 2%;
        margin-right: 2%;
      }
      .list:hover{
        .img{
          img{
            -webkit-transform:scale(1.1);
            transform:scale(1.1);
          }
        }
      }
    }
    .ad{
      margin-bottom: 2%;
      display: flex;
      flex-wrap: wrap;
      .list{
        width: 49%;
        cursor: pointer;
        img{
          width: 100%;
        }
      }
      .list:nth-child(2n+1){
        margin-right: 2%;
      }
    }
  }
  .foot{
    background-color: #333740;
    padding: 4% 0;
    .box{
      display: flex;
      align-items: flex-start;
      .list{
        margin-right: 8%;
        h5{
          font-size: 14px;
          font-weight: 600;
          color: #FFFFFF;
          margin-bottom: 15px;
        }
        p{
          font-size: 12px;
          font-weight: 400;
          color: #B3B3B3;
          margin-bottom: 10px;
          a{
            color: #B3B3B3;
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.index{
  .banner{
    .el-carousel__item {
      cursor: pointer;
      img{
        width: 100%;
      }
    }
    .el-carousel__arrow {
      width: 54px;
      height: 54px;
      font-size: 30px;
    }
    .el-carousel__indicators--horizontal {
      bottom: 15px;
    }
    .el-carousel__button {
      width: 14px;
      height: 14px;
      border-radius: 14px;
    }
    .el-carousel__indicator.is-active button {
      background: #1FC9C2;
    }
  }
  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    background-color: white;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #1FC9C2;
  }
  .blog{
    .el-image{
      width: 376px;
      height: 230px;
      img{
        border-radius: 6px;
      }
    }
  }
}
</style>
